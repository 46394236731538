:root {
  --background: #fef9f5;
  --white: #ffff;
  --black: #000000;
  --grey: #ccc;
  --button-background: #02b8ce;
  --button-hover: #fff9e5;
  --primary-color: #ffcc00;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: var(--background);
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

ul li {
  list-style-type: none;
}
section {
  margin-bottom: 20px;
}
img {
  width: 100%;
}
.container {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-align-center {
  text-align: center;
}
.bold,
label {
  font-weight: 500;
}
.dark {
  font-weight: 300;
}

.logo {
  width: 60px;
}
.flex {
  display: flex;
}
.mt-20 {
  margin-top: 20px;
}
.btn {
  background-color: var(--button-background);
  color: var(--white);
  padding: 8px 20px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.btn-red {
  background-color: red;
}
/* .btn:hover,
.btn .active {
  background-color: var(--primary-color);
  color: var(--black);
  border-radius: 20px;

  transition: background 0.3s ease;
} */
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--primary-color);
  color: var(--black);
  border-radius: 20px;

  transition: background 0.3s ease;
}

.btn-center {
  display: block;
  margin: auto;
}

/* ==== Swiper */
/* Swiper Styles */
.swiper-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.slick-slide {
  display: flex;
  justify-content: center;
}

/* Slick Slider Overrides */
.slick-prev,
.slick-next {
  /* background-color: black; */
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  /* transform: rotate(180deg); */
}

.slick-prev {
  top: 50%;
  left: 16px;
}

.slick-next {
  top: 50%;
  right: 16px;
}

.slick-prev:before {
  top: 50%;
  left: 16px;
  background-color: #000;
  border-radius: 50px;
}

.slick-next:before {
  top: 50%;
  right: 16px;
  background-color: #000;
  border-radius: 50px;
}
/* .slick-slide {
  margin: 0 15px;
} */
/* .category-slider-wrapper .next {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: rotate(180deg);
} */
.slick-list {
  margin: 0 40px;
}
.slick-list {
  margin: 30px;
}

/* === */
.slick-slide {
  margin: 0 10px; /* Adjust this value as needed for spacing */
}

/* To ensure that the first and last items don't get extra margins */
.slick-track {
  display: flex; /* Ensures slick-slide items are aligned properly */
  /* transform: translate3d(0, 0, 0) !important; */
}

.slick-slide:first-child {
  margin-left: 0;
}

.slick-slide:last-child {
  margin-right: 0;
}

/* Optional: if you want to add a little extra space between each slide on mobile */
@media (max-width: 768px) {
  .slick-slide {
    margin: 0 5px; /* Adjust for smaller space on mobile if needed */
  }
}

/* ====End Swiper */

/* =====nav */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar.scrolled {
  background-color: var(--white);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.navbar-logo img {
  width: 50px;
}

.navbar-search input {
  width: 300px;
  padding: 5px 10px;
  border: 1px solid var(--grey);
  border-radius: 20px;
}

.navbar-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar-links a {
  text-decoration: none;
  color: var(--black);
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: var(--button-background);
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: var(--black);
  transition: transform 0.3s ease;
}

.navbar-links.open {
  display: flex;
}

.user-menu {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
  color: var(--black);
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--white);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  width: 150px;
  display: block;
}

.dropdown-menu a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: var(--black);
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover {
  background-color: var(--white);
}
/* 
.banner {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  background-image: url("../public/img/banner-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 70px);
}

.banner-text {
  max-width: 600px;
  padding: 100px 150px;
}

.banner-text h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--white);
}

.banner-text p {
  font-size: 1.2rem;
  color: var(--white);

  margin-bottom: 20px;
}

.banner-image {
  position: relative;
}

.circle {
  width: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 4;
}
.circle .circle-img {
  position: relative;
  z-index: 2;
}
.circle .hero-child {
  width: 322px;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  top: 135px;
} */

.banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  background-image: url("../public/img/banner-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 70px);
  background-position: center; /* Ensure background covers the entire banner */
}

.banner-text {
  max-width: 800px;
  padding: 50px 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--white);
}

.banner-text h1 {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.3; /* Improve readability */
}

.banner-text p {
  font-size: 2rem;
  color: var(--white);
  margin-bottom: 20px;
}

.banner-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 100%; /* Make circle take full width for responsiveness */
  max-width: 800px; /* Ensure it doesn't grow too large on big screens */
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 20px;
}

.circle .circle-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.circle .hero-child {
  width: 60%; /* Make the child image responsive */
  max-width: 380px;
  object-fit: cover;
  position: absolute;
  top: 15%;
  z-index: 1;
  transition: top 0.3s ease, width 0.3s ease;
}

/* ===cards */
.card {
  width: 100%;
  background: var(--white);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
  text-align: center;
  position: relative;
}

.card-header {
  background: none;
  position: absolute;
  top: 15px;
  right: 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.share-icon,
.bookmark-icon {
  font-size: 18px;
  cursor: pointer;
  color: var(--black);
}

.profile-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 15px;
  object-fit: cover;
}

.name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.amount {
  font-size: 18px;
  color: var(--black);
  font-weight: bold;
}

.time-left {
  font-size: 14px;
  color: var(--black);
  margin-bottom: 20px;
}

/* ==== category-container*/

/* .category-container .container {
  margin-top: -12%;
  z-index: 9;
  position: relative;
} */
.sponserKidsContainer {
  margin-top: 70px;
}
.heading {
  position: relative;
}
.my-3 {
  margin-top: -7% !important;
}

.categories button {
  border: none;
}
.category-btn {
  margin-right: 10px;
}
.category-btn.active {
  background-color: var(--white);
}
/* ==== Child-container*/
.child-container {
  background-image: url("../public/img/kids-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 530px;
}

/* =====Profile */

.profile-header {
  position: relative;
}
.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.photo-item {
  width: 23%;
}

.photo-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
}

.document-item {
  margin: 10px 0;
}

.document-gallery {
  display: flex;
  flex-direction: column;
}

/* =====Footer */
.footer-container {
  padding: 20px;
  border-top: 1px solid #f4e3ce; /* Slight border for separation */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin: 0 auto; */
  flex-wrap: wrap;
}

.footer-section {
  flex: 2;
  text-align: center;
  margin: 10px;
}

.logo-section {
  flex: 1;
}

.contact-section,
.social-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.links-section ul,
.links-section2 ul,
.social-section a {
  list-style: none;
  text-align: left;
  padding: 0;
  text-decoration: none;
}

.links-section li,
.links-section2 li,
.social-section a {
  margin: 5px 0;
  font-size: 1rem;
  color: #000;
  cursor: pointer;
}

.links-section li:hover,
.links-section2 li:hover,
.social-section a:hover {
  text-decoration: underline;
}

/* Icon Styling */
.contact-icon,
.social-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.contact-item,
.social-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

/* ======Performance of the Year  */
.performer-of-the-year {
  background-image: url("../public/img/story-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 590px;
}
.performerHeading {
  padding: 20px 0;
}
.performer-card {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
  margin: auto;
  position: relative;
  height: 300px;
}

.performer-image {
  flex: 0 0 150px;
}

.performer-info {
  flex: 1;
  padding: 0 20px;
}

.performer-name {
  font-size: 24px;
  font-weight: bold;
}

.performer-description {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.sponsor-button {
  background: #00bcd4;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.sponsor-button:hover {
  background: #0097a7;
}

.icons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.icon {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
  color: gray;
}

/* Footer Logo */
.footer-logo {
  width: 80px;
  height: auto;
}

/* ======Error Message ====== */
.errorModule {
  margin: 40px auto 20px;
  text-align: center;
  color: #ffcc00;
}
.errorModule .errorIcon {
  font-size: 34px;
  margin: 15px;
  animation: animateIcon 5s infinite;
}
.errorModule .errorMsg {
  font-size: 14px;
}
@keyframes animateIcon {
  0% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ============= Loader ==== */
.outer-ripple {
  /* change color here */
  color: #fdc513;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

/* +=====For Tabs */
/* @media screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-image .circle {
    max-width: 525px;
  }
} */

@media (max-width: 1024px) {
  .banner {
    flex-direction: column;
    height: auto;
    padding: 40px 15px;
  }

  .banner-text {
    padding: 40px 10px;
    text-align: left;
  }

  .banner-text h1 {
    font-size: 2.5rem;
  }

  .banner-text p {
    font-size: 1rem;
  }

  .cta-btn {
    margin-top: 20px;
  }

  .circle {
    max-width: 700px;
    margin-top: 20px;
  }

  .circle .hero-child {
    width: 50%;
    max-width: 265px;
    top: 20%;
  }
}
/* @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .banner-image .circle {
    max-width: 525px;
  }
} */

@media (max-width: 768px) {
  .custom-prev,
  .custom-next {
    font-size: 1.5rem;
  }
  .navbar-search {
    flex-grow: 1;
    margin: 0 10px;
  }
  .navbar-search input {
    width: 200px;
  }

  .navbar-links {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 15px;
    padding: 10px 0;
  }

  .hamburger {
    display: flex;
  }

  .navbar-links.open {
    display: flex;
    background-color: var(--white);
    height: 100vh;
  }

  .navbar-links a,
  .donate-btn {
    width: 90%;
    text-align: center;
  }
  .navbar-links {
    flex-direction: column;
  }

  .user-menu {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .dropdown-menu {
    width: 90%;
    right: 5%;
  }
  /* === */
  /* .banner {
    flex-direction: column;
    text-align: center;
    display: block;
  }

  .banner-text {
    margin-bottom: 30px;
  }

  .circle {
    width: 250px;
    height: 250px;
  }

  .circle img {
    width: 150px;
  } */
  .banner {
    flex-direction: column;
    height: auto;
    padding: 30px 10px;
  }

  .banner-text {
    padding: 30px 15px;
    text-align: left;
  }

  .banner-text h1 {
    font-size: 2rem;
  }

  .banner-text p {
    font-size: 0.9rem;
  }

  .cta-btn {
    padding: 12px 25px;
    font-size: 0.9rem;
    margin-top: 20px;
  }

  .circle {
    width: 80%;
    max-width: 500px;
    margin-top: 20px;
  }

  .circle .hero-child {
    width: 50%;
    max-width: 265px;
    top: 25%; /* Further adjust for smaller screens */
  }

  .card {
    width: 100%;
    margin: 10px 0;
    padding: 15px;
  }
  .performer-card {
    flex-direction: column;
    text-align: center;
    height: auto;
  }

  .performer-image {
    margin-bottom: 15px;
  }

  .performer-info {
    padding: 0;
  }
  .categories {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .category-btn {
    margin-bottom: 10px;
  }

  .footer-content {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }

  .footer-section {
    text-align: left;
    margin: 15px 0;
  }

  .links-section {
    margin-top: 20px;
  }

  .footer-logo {
    margin: 15px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* Your styles here */
  .banner-image .circle {
    max-width: 525px;
  }
}
@media (max-width: 480px) {
  .circle {
    width: 90%;
    max-width: 400px;
  }

  .circle .hero-child {
    width: 50%; /* Increase width for smaller screens */
    top: 20%; /* Fine-tune position */
  }
}
