/* .profile-main-container {
  margin-top: 70px;
}
.profile-bg {
  width: 100%;
  position: relative;
}
.profile-form-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: -6%;
  z-index: 9;
  position: relative;
}
.profile-avatar {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}
.profile-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffd54f;
}

.edit-avatar {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #ff6f00;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}
.edit-avatar input[type="file"] {
  display: none;
}
.profile-form {
  margin-left: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: calc(100vh - 210px);
  overflow: auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  resize: vertical;
}

.save-button {
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

@media (max-width: 768px) {
  .profile-form-container {
    padding: 10px;
    display: block;
  }

  .profile-form {
    padding: 15px;
    margin: 0;
  }
} */

/* =====  */

/* .profile-main-container {
  margin-top: 70px;
}
.profile-bg {
  width: 100%;
  position: relative;
}
.profile-form-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: -6%;
  z-index: 9;
  position: relative;
}
.profile-avatar {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}
.profile-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffd54f;
}

.edit-avatar {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #ff6f00;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}
.edit-avatar input[type="file"] {
  display: none;
}
.profile-form {
  margin-left: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: calc(100vh - 120px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
}

.form-group {
  margin-bottom: 15px;
  width: 30%;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  resize: vertical;
}

.save-button {
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 50%;
}

@media (max-width: 768px) {
  .profile-form-container {
    padding: 10px;
    display: block;
  }
  .form-group {
    margin-bottom: 15px;
    width: 100%;
  }

  .profile-form {
    padding: 15px;
    margin: 0;
    display: block;
  }
} */
/* Add a smooth fade-in animation for the avatar and form */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.profile-main-container {
  margin-top: 70px;
}

.profile-bg {
  width: 100%;
  position: relative;
  animation: fadeIn 0.5s ease-in-out; /* Animation on page load */
}

.profile-form-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: -6%;
  z-index: 9;
  position: relative;
  display: flex;
  justify-content: center; /* Centers form horizontally */
  animation: fadeIn 0.5s ease-in-out;
}

.profile-avatar {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  animation: fadeIn 0.5s ease-in-out;
}

.profile-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffd54f;
}

.edit-avatar {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #ff6f00;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease-in-out;
}

.edit-avatar:hover {
  background-color: #e65100; /* Darker shade on hover */
}

.edit-avatar input[type="file"] {
  display: none;
}

.profile-form {
  margin-left: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: calc(100vh - 120px);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* This ensures equal column width */
  animation: fadeIn 0.5s ease-in-out;
}

.profile-main-container .form-group {
  margin-bottom: 15px;
  width: 45%; /* Each form group takes 45% width to maintain equal columns */
}

.profile-main-container .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.profile-main-container .form-group input,
.profile-main-container .form-group select,
.profile-main-container .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.profile-main-container .form-group input:focus,
.profile-main-container .form-group select:focus,
.profile-main-container .form-group textarea:focus {
  border-color: #ff6f00; /* Highlight on focus */
}

.profile-main-container textarea {
  resize: vertical;
}

.save-button {
  color: white;
  border: none;
  cursor: pointer;
  width: 48%; /* Reduced width to have space between the buttons */
  transition: background-color 0.3s ease-in-out;
}

.save-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .profile-form-container {
    flex-direction: column;
    padding: 10px;
  }

  .profile-avatar {
    width: 100px;
    height: 100px;
  }

  .profile-main-container .form-group {
    width: 100%;
    margin-bottom: 20px;
  }

  .save-button {
    width: 100%;
  }

  .profile-main-container .profile-form {
    margin-left: 0;
    height: auto;
    padding: 15px;
  }
}
