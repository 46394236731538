/* General Styles */
.mentor-profile-container {
  margin-top: 70px;
}

.mentor-header {
  text-align: center;
  background-image: url("/public/img/mentor-profile-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 280px;
  position: relative;
  width: 100%;
}
.mentor-profile-pic {
  width: 247px;
  height: 247px;
  border-radius: 50%;
  margin-bottom: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.mentor-profile-name {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 48px;
}
/* Sponsored Kids Section */
.sponsored-kids,
.sponsor-kids {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
  justify-content: center;
}

.kid-card {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 1 calc(25% - 10px);
}

.kid-card h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.kid-card button {
  margin: 5px 0;
}

/* Transaction History Section */
.transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.transaction-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .mentor-header {
    height: 200px;
  }
  .mentor-profile-pic {
    width: 150px;
    height: 150px;
  }
  .kid-card {
    flex: 1 1 calc(50% - 10px);
  }
  .transaction-table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .transaction-table {
    width: 100%;
    table-layout: fixed;
  }

  .transaction-table th,
  .transaction-table td {
    white-space: nowrap;
    word-wrap: break-word;
    font-size: 10px;
    padding: 0 15px;
  }
  .sponsored-kids,
  .sponsor-kids {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
}
