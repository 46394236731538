/* General Styling */
.profile-container {
  margin-top: 70px;
}

/* Header Section */
.profile-header .kid-profile-banner {
  width: 100%;
  position: absolute;
  bottom: -130px;
  height: 289px;
  left: 0;
}
.kid-profile-avatar {
  width: 247px;
  height: 247px;
  border-radius: 50%;
}

.profile-bg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.profile-details {
  position: absolute;
  bottom: -65px;
  left: 50px;
  width: 100%;
}
.profile-meta {
  width: 95%;
  justify-content: space-around;
  background: white;
  border-radius: 30px;
  align-items: center;
  padding: 5px 0;
}
.profile-meta span {
  display: block;
  font-size: 14px;
  margin: 5px 0;
}
.profile-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2px;
}

.profile-actions {
  text-align: center;
}

.profile-actions .btn-primary {
  margin-right: 10px;
}

/* Profile Section */
.profile-section {
  margin-top: 140px;
}
.profile-card {
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.profile-card-pic {
  width: 300px;
  height: 300px;
  margin-right: 20px;
}

/* Expense Section */
.expense-section {
  background-color: #fff8f0;
  padding: 20px;
  border-radius: 10px;
}

.expense-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.expense-card {
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 300px;
  height: 80px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.expense-card.basic {
  background: #ffcc00;
}
.expense-card.education {
  background: #00bcd4;
}
.expense-card.curriculum {
  background: #4caf50;
}
.expense-card.activity {
  background: #ff5722;
}
/* Photos Section */
.media-section .react-tabs__tab-list {
  border: none;
}
.media-section {
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  text-align: center;
}
.media-section .btn {
  padding: 8px 70px;
}
.photo-gallery img {
  width: 100px;
  height: 100px;
  margin: 10px;
  border-radius: 10px;
}

/* Responsive Design for screens <= 768px */
@media (max-width: 768px) {
  .profile-header .kid-profile-banner {
    bottom: -75px;
    height: 100px;
  }
  .profile-card {
    display: block;
  }
  .profile-card-pic {
    width: 250px;
    height: 250px;
  }
  .profile-section {
    margin-top: 205px;
  }
  .profile-details {
    top: 50%;
    width: 80%;
  }
  .kid-profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .profile-meta span {
    font-size: 10px;
  }

  .expense-cards {
    flex-direction: column;
    align-items: center;
  }

  .expense-card {
    width: 100%;
    max-width: 300px;
  }

  .photo-gallery img {
    width: 80px;
    height: 80px;
  }
}
