.login-register-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.gradient-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/public/img/Login-gradient.jpeg") no-repeat center
    center/cover;
  z-index: 1;
  transition: transform 0.5s ease-in-out;
}

.gradient-bg.slide-down {
  transform: translatex(10%);
}

.login-form-container,
.otp-form-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  max-width: 500px;
  padding: 30px;
  text-align: center;
  left: 100px;
  top: 80px;
}
.register-form-container {
  width: 100%;
  max-width: 934px;
  z-index: 2;
  position: absolute;
  left: 100px;
  top: 10px;
}
.register-form {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.form-left,
.form-right {
  display: flex;
  flex-direction: column;
  flex: 0 0 48%;
  gap: 2rem;
}

.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  margin: 0 auto;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.register-form {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.form-left,
.form-right {
  display: flex;
  flex-direction: column;
  flex: 0 0 48%;
}

label {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

textarea {
  resize: none;
}

.create-account,
.back-to-login {
  margin-top: 10px;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.create-account:hover,
.back-to-login:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .login-register-container {
    overflow-y: auto;
    /* height: 100%; */
  }

  .gradient-bg {
    background: none;
  }
  .login-form-container,
  .register-form-container,
  .otp-form-container {
    width: 90%;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  input,
  select,
  textarea {
    font-size: 12px;
  }
}
